(window as any).global = window;

const props = ['scroll', 'mouseenter', 'mouseleave', 'mousemove', 'mouseover', 'mouseout', 'mousewheel', 'pointermove'];
// black list scroll event handler for onProp
const targets = [window, Document, HTMLBodyElement, HTMLElement];
(window as any).__Zone_ignore_on_properties = [];
targets.forEach(function (target) {
    (window as any).__Zone_ignore_on_properties.push({
        target: target,
        ignoreProperties: props
    });
});

// disable requestAnimationFrame
(window as any).__Zone_disable_requestAnimationFrame = true;
// Zone will not patch onProperty such as button.onclick, not sure if this should be left disabled...
// (window as any).__Zone_disable_on_property = true;
(window as any).__zone_symbol__BLACK_LISTED_EVENTS = props;
(window as any).__Zone_enable_cross_context_check = true;

// disable addEventListener
(window as any).global['__zone_symbol__BLACK_LISTED_EVENTS'] = props;
